#sectionSkills {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#cardMain {
  background-color: #0B0B0F;
  width: 960px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 15px;
  padding: 50px 25px;
  box-shadow: inset 0 0 0 1px #26242B;
}

#skillsItems {
  display: block;
  background-color: #1A1A22;
  padding: 56px;
  border-radius: 15px;
  width: 51.9%;
  margin-top: -200px;
  margin-bottom: -200px;
  box-shadow: inset 0 0 0 1px #26242B;
}

#cardTitle {
  font-size: 28px;
}

.cardSubTitle {
  font-size: 16px;
  margin: 30px 0 8px 0;
}

#gradientLine {
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #0099ff, #0000ff);
  border-radius: 5px;
}

.technologyGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.technologyBox {
  background-color: #0B0B0F;
  padding: 3.38%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.technologyBox img {
  width: 28px;
  height: 28px;
}

#skillsDescription {
  width: 40%;
}

#h2Card {
  font-size: 13px;
}

.pCard {
  font-size: 13px;
  color: rgb(161, 163, 187);
}

.custom-tooltip .tooltip-inner {
  position: relative;
  background-color: rgb(0, 56, 102);
  color: #2180C1;
  padding: 0 10px;
  border-radius: 5px;
  font-weight: 600;
}

/* Triângulo indicador na parte inferior */
.custom-tooltip .tooltip-inner::before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(0, 56, 102) transparent transparent transparent;
  transform: translateX(-50%);
}

/* Borda para o triângulo */
.custom-tooltip .tooltip-inner::after {
  content: '';
  position: absolute;
  bottom: -11px;
  left: 50%;
  border-width: 6px;
  border-style: solid;
  border-color: rgb(0, 56, 102) transparent transparent transparent;
  transform: translateX(-50%);
}

@media (max-height: 780px) {
  #cardMain {
    margin-top: 50px;
  }
}

@media (max-width: 1000px) {
  #cardMain {
    width: 551px;
    flex-direction: column;
    padding: 50px 0;
    margin: 0 30px;
  }

  #skillsItems {
    width: 85%;
    margin-top: 0;
    margin-bottom: 0;
  }

  #skillsDescription {
    width: 85%;
    margin-top: 30px;
  }
}

@media (max-width: 630px) {
  #cardMain {
    margin-top: 0;
  }

  #cardMain {
    width: 332px;
    padding: 30px 0;
  }

  #skillsItems {
    padding: 45px;
  }

  .technologyBox {
    padding: 10px;
  }

  #cardTitle {
    font-size: 24px;
    text-align: center;
  }
  
  .cardSubTitle {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  #cardMain {
    width: 202px;
    padding: 20px 0;
  }

  #skillsItems {
    padding: 25px;
  }

  .technologyGroup {
    gap: 0.5rem;
  }  

  .technologyBox {
    padding: 10px;
  }

  .technologyBox img {
    width: 15px;
    height: 15px;
  }

  #cardTitle {
    font-size: 15px;
    text-align: center;
  }
  
  .cardSubTitle {
    font-size: 11px;
  }

  #h2Card {
    font-size: 11px;
  }
  
  .pCard {
    font-size: 11px;
    color: rgb(161, 163, 187);
  }
}
