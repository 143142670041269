@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;500;600;700&family=Poppins:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*::selection {
  background-color: #2181c15b;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

body, html {
  scrollbar-width: thin;
  scrollbar-color: #a1a3bb31 #000000d2;

  ::-webkit-scrollbar {
    width: 7px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #000000;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #a1a3bb31;
    border-radius: 8px;
  }

  scrollbar-width: unset;
  scrollbar-color: #a1a3bb31 #000000d2;
  -ms-overflow-style: none;
}

body {
  background-color: black;
  color: white;
  font-family: 'Fira Code', monospace;
  font-family: 'Poppins', sans-serif;
}
