#sectionContact {
  width: 100%;
  background-color: #0B0B0F;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 70px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.contactBox {
  width: 25%;
  height: 100%;
  padding: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contactBox2 {
  width: 25%;
  height: 100%;
  padding: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contactBox h2 {
  margin-bottom: 15px;
}

#logoImg {
  width: 45px;
  margin-top: 70px;
  margin-bottom: 20px;
}

#sectionContact p {
  color:#A1A3BB;
  font-size: 17px;
  margin-top: 3px;
}

#contactLinks {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-top: 30px;
}

.linksBox {
  padding: 10px;
  background-color: #1A1A22;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.linksBox:hover {
  background-color: #272731;
}

.linksBox img {
  width: 28px;
}

#emailLink {
  color:#A1A3BB;
  font-size: 17px;
  text-decoration: none;
  margin-top: 15px;
}

#rights {
  width: 100%;
  height: 8vh;
  background-color: #0B0B0F;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #26242B;
}

#rights p  {
  color:#A1A3BB;
  font-size: 14px;
}

@media (max-width: 1760px) {
  #sectionContact {
    height: 100%;
  }

  .contactBox {
    width: 40%;
  }
  
  .contactBox2 {
    width: 40%;
    height: 100%;
  }
}

@media (max-width: 1060px) {
  .contactBox h2 {
    font-size: 17px
  }

  .contactBox2 h2 {
    font-size: 17px;
  }
  
  #logoImg {
    width: 40px;
  }
  
  #sectionContact p {
    color:#A1A3BB;
    font-size: 12px;
  }

  #emailLink {
    font-size: 12px;
  }

  #rights p  {
    font-size: 12px;
  }
}

@media (max-width: 810px) {
  .contactBox {
    display: none;
  }

  .contactBox2 {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .contactBox2 h2{
    font-size: 14px;
  }
}