#sectionStudies {
  width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: 30px;
}

#titleSectionStudies {
  font-size: 28px;
  margin-bottom: 5px;
}

#descriptionSectionStudies {
  font-size: 16px;
  color: rgb(161, 163, 187);
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin: 50px auto;
}

.containerTimeline {
  padding: 10px 50px 0 40px;
  position: relative;
  width: 50%;
  animation: movedown 1s linear forwards;
  opacity: 0;
}

.visible .containerTimeline {
  animation-name: movedown-visible;
}

@keyframes movedown-visible {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes movedown {
  0% {
    opacity: 1;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.containerTimeline:nth-child(1) {
  animation-delay: 0s;
}

.containerTimeline:nth-child(2) {
  animation-delay: 1s;
}

.containerTimeline:nth-child(3) {
  animation-delay: 2s;
}

.containerTimeline:nth-child(4) {
  animation-delay: 3s;
}

.containerTimeline:nth-child(5) {
  animation-delay: 4s;
}

.containerTimeline:nth-child(6) {
  animation-delay: 5s;
}

.textBox {
  padding: 20px 30px;
  background: #1A1A22;
  position: relative;
  border-radius: 6px;
  font-size: 15px;
  box-shadow: inset 0 0 0 1px #26242B;
}

.leftContainer {
  left: 0;
}

.rightContainer {
  left: 50%;
}

.containerTimeline img {
  position: absolute;
  width: 40px;
  border-radius: 5px;
  right: -20px;
  top: 32px;
  z-index: 10;
  background: #1A1A22;
  box-shadow: inset 0 0 0 1px #26242B;
}

.rightContainer img {
  left: -20px;
}

.textBox h3 {
  font-weight: 600;
  font-size: 20px;
}

.textBox h6 {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgb(33, 128, 193);
  font-size: 12px;
  letter-spacing: .05em;
}

.textBox p {
  color: rgb(161, 163, 187);
}

.leftContainerArrow {
  height: 0;
  width: 16px;
  position: absolute;
  top: 28px;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #1A1A22;
  right: -15px;
}

.rightContainerArrow {
  height: 0;
  width: 16px;
  position: absolute;
  top: 28px;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #1A1A22;
  left: -15px;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 0; /* Alterado para iniciar com altura zero */
  background: #1A1A22;
  top: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
  box-shadow: inset 0 0 0 1px #26242B;
}

.timeline.visible::after {
  animation: moveline 6s linear forwards; /* Adicionado para iniciar a animação somente quando a classe 'visible' estiver presente */
  height: 100%; /* A altura será ajustada para 100% quando a classe 'visible' for aplicada */
}

@keyframes moveline {
  0% {
    height: 0; /* Inicializa com altura zero */
  }
  100% {
    height: 100%; /* A altura será ajustada para 100% no final da animação */
  }
}

@media (max-width: 1000px) {
  #sectionStudies {
    width: 95%;
  }
}

@media (max-width: 960px) {
  #sectionStudies {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .timeline {
    margin: 40px auto;
  }

  .timeline::after {
    left: 31px;
  }

  .containerTimeline {
    width: 100%;
    padding-left: 80px;
    padding-right: 25px;
  }

  .rightContainer {
    left: 0;
  }

  .leftContainer img, .rightContainer img {
    left: 10px;
  }

  .leftContainerArrow, .rightContainerArrow {
    border-right: 15px solid #1A1A22;
    border-left: 0;
    left: -15px;
  }

  #descriptionSectionStudies {
    font-size: 14px;
  }

  #titleSectionStudies {
    font-size: 24px;
  }

  .textBox h3 {
    font-size: 18px;
  }

  .textBox h6 {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .textBox p {
    font-size: 14px;
  }
}

@media (max-width: 465px) {
  #descriptionSectionStudies {
    font-size: 12px;
  }

  #titleSectionStudies {
    font-size: 22px;
  }

  .textBox h3 {
    font-size: 16px;
  }

  .textBox h6 {
    font-size: 10px;
  }

  .textBox p {
    font-size: 12px;
  }
}