#sectionProjects {
  width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: 100px;
}

#titleSectionProjects {
  font-size: 28px;
  margin-bottom: 5px;
}

#descriptionSectionProjects {
  font-size: 16px;
  color: rgb(161, 163, 187);
}

.containerProject {
  background-color: #0B0B0F;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 40px 40px 0 40px;
  border-radius: 15px;
  box-shadow: inset 0 0 0 1px #26242B;
}

.containerBack {
  padding: 40px 40px 40px 40px;
}

.containerProject p {
  font-size: 16px;
  color: rgb(161, 163, 187);
}

.containerProject h3 {
  font-size: 20px;
  margin-top: 10px;
}

.containerProject h6 {
  margin-top: 10px;
  font-size: 14px;
  color: #2180C1;
  letter-spacing: .05em;
  margin-bottom: 30px;
}

.btn {
  border: none;
  background-color: rgba(0, 56, 102, 0.705);
  color: #d3dee0;
  cursor: pointer;
  font-weight: bolder;
  text-decoration: none;
}

.btn:hover {
  background-color: rgb(0, 56, 102);
}

.Deploy {
  border-radius: 3.5px 0 0 3.5px;
  padding: 6px 10px;
}

.GitHub {
  padding: 6px 19.3px;
  border-radius: 0 3.5px 3.5px 0;
  border-left: 2px solid #000000;
}

.GitHubBack {
  border-radius: 3.5px;
  border-left: 0 solid #000000;
}

.containerLinks {
  display: flex;
  margin-top: 40px;
}

.containerLinks img{
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 0 0;
}

#gradientLine2 {
  width: 50%;
  height: 4px;
  background: linear-gradient(to right, #0099ff, #0000ff);
  border-radius: 5px;
}

.projectTechnologies {
  list-style: none;
  display: flex;
  margin-top: 20px;
  margin-right: 30px;
  flex-wrap: wrap;
}

.projectTechnologies li {
    display: block;
    width: fit-content;
    border-radius: 1.25rem;
    padding: 0.38rem 0.75rem;
    background: rgba(0, 56, 102, 0.705);
    color: #2180C1;
    font-weight: 600;
    margin-top: 10px;
    margin-right: 7px;
}

#moreProjects {
  margin: 50px 0 100px 0;
  text-align: center;
} 

#moreProjects a {
  font-size: 2.5rem;
  font-weight: 600;
  padding-bottom: 8px;
  border-width: 4px;
  cursor: pointer;
  color: rgb(161, 163, 187);
  border-bottom-style: solid;
  text-decoration: none;
  transition: color 0.3s ease;
}

#moreProjects a:hover {
  color: #2181C1;
}

@media (max-height: 780px) {
  #sectionProjects {
    margin-top: 200px;
  }
}

@media (max-width: 1000px) {
  #sectionProjects {
    width: 95%;
  }
  
  .containerProject {
    width: 100%;
  }
}

@media (max-width: 960px) {
  #sectionProjects {
    width: 85%;
  }
  
  .containerProject {
    width: 100%;
  }
}

@media (max-width: 600px) {
  #sectionProjects {
    margin-top: 100px;
  }

  #titleSectionProjects {
    font-size: 24px;
  }
  
  #descriptionSectionProjects {
    font-size: 14px;
  }

  .containerProject p {
    font-size: 14px;
  }
  
  .containerProject h3 {
    font-size: 18px;
  }
  
  .containerProject h6 {
    font-size: 14px;
  }

  .Deploy {
    font-size: 12px;
  }
  
  .GitHub {
    font-size: 12px;
  }

  .projectTechnologies li {
    font-size: 12px;
  }

  #moreProjects a {
    font-size: 2rem;
  }
}

.imageContainer {
  position: relative;
  display: inline-block;
}

.projectImage {
  display: block;
  width: 100%;
  height: auto;
}

.imageText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  transition: opacity 0.3s ease;
}

.imageContainer:hover .imageText {
  opacity: 1;
}

.imageContainer:hover .projectImage {
  filter: brightness(50%);
}

@media (max-width: 465px) {
  .containerProject {
    padding: 30px 0 0 0;
  }

  .containerBack {
    padding: 30px 0 30px 0;
  }

  #titleSectionProjects {
    font-size: 22px;
  }
  
  #descriptionSectionProjects {
    font-size: 12px;
  }

  .containerProject p {
    font-size: 12px;
    margin-left: 30px;
    margin-right: 30px;
    flex-wrap: wrap;
  }
  
  .containerProject h3 {
    font-size: 16px;
    margin-left: 30px;
    margin-right: 30px;
    flex-wrap: wrap;
  }
  
  .containerProject h6 {
    font-size: 12px;
    margin-left: 30px;
    margin-right: 30px;
    flex-wrap: wrap;
  }

  .GitHubBack {
    margin-left: 30px;
    margin-right: 30px;
  }

  .Deploy {
    margin-left: 30px;
    font-size: 10px;
  }
  
  .GitHub {
    font-size: 10px;
  }

  .containerLinks img{
    border-radius: 0 0 15px 15px;
  }

  .projectTechnologies {
    margin-left: 30px;
  }

  .projectTechnologies li {
    font-size: 10px;
  }

  #moreProjects a {
    font-size: 1.5rem;
  }
}
