#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  margin: 0 center;
  border-bottom: 1px solid #26242B;
  z-index: 999;
  background-color: black;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

#logo {
  margin-left: 20px;
}

#logo-img {
  width: 40px;
  margin: 10px;
  user-select: none;
  pointer-events: none;
}

#nav-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin: 14.4px 14.4px 15px 0;
}

#nav-list a {
  color: white;
  text-decoration: none;
  color: rgb(161, 163, 187);
  padding: 16px;
}

#nav-list a:hover {
  color: #2180C1;
}

#menuMobile {
  display: none;
  border: none;
  background: none;
  border-top: 3px solid #fff;
  cursor: pointer;
  margin-right: 40px;
}

#menuMobile::after,
#menuMobile::before {
  content: " ";
  display: block;
  width: 30px;
  height: 3px;
  background: #fff;
  margin-top: 5px;
  position: relative;
  transition: 0.3s;
  border-radius: 3px;
}

@media (max-width: 960px) {
  #header {
    width: 100%;
  }
}

@media (max-width: 600px) {
  #logo {
    margin-left: 2px;
  }

  #menuMobile {
    display: block;
    z-index: 1001;
    margin-right: 20px;
  }

  #nav-list {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100vh;
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0.815);
    clip-path: circle(100px at 90% -15%);
    transition: 1s ease-out;
    backdrop-filter: blur(10px);
    z-index: 1000;

    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    pointer-events: none;
    margin: 0;
  }

  #nav.active #nav-list {
    clip-path: circle(1500px at 90% -15%);
    pointer-events: all;
  }

  #nav.active #menuMobile {
    position: fixed;
    top: 24px;
    right: 0;
    border-top-color: transparent;
  }
  
  #nav.active #menuMobile::before {
    transform: rotate(135deg);
  }
  
  #nav.active #menuMobile::after {
    transform: rotate(-135deg);
    top: -7px;
  }
}