#sectionHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 91.3vh;
  width: 50%;
  margin: 0 auto;
  min-height: 100vh;
}

#mainTitle {
  margin-top: 2%;
  font-size: 4.4rem;
  line-height: 1.1;
  text-align: center;
}

#helloText {
  color: #2180C1;
  font-size: 20px;
}

#introText {
  margin-top: 4%;
}

.textHome {
  color: rgb(161, 163, 187);
  font-size: 20px;
  line-height: 1.75rem;
  text-align: center;
}

#btn-contact {
  margin-top: 5%;
}

@media (max-height: 780px) {
  #sectionHome {
    height: 120vh;
  }

  #mainTitle {
    font-size: 3.4rem;
  }

  #helloText {
    font-size: 16px;
  }
  
  .textHome {
    font-size: 16px;
  }
}

@media (max-height: 470px) {
  #sectionHome {
    height: 590px;
  }

  #mainTitle {
    font-size: 2.4rem;
  }

  #helloText {
    font-size: 16px;
  }
  
  .textHome {
    font-size: 16px;
  }
}

@media (max-width: 1145px) {
  #sectionHome {
    width: 90%;
  }
}

@media (max-width: 870px) {
  #mainTitle {
    font-size: 50px;
  }

  #helloText {
    font-size: 17px;
  }

  .textHome {
    font-size: 17px;
  }
}

@media (max-width: 635px) {
  #sectionHome {
    height: 91.3vh;
  }

  #mainTitle {
    font-size: 35px;
  }

  #helloText {
    font-size: 14px;
  }

  .textHome {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 450px) {
  #mainTitle {
    font-size: 32px;
  }

  #helloText {
    font-size: 12px;
  }

  .textHome {
    font-size: 12px;
    line-height: 20px;
  }

  #btn-contact {
    margin-top: 8%;
  }
}

@media (max-width: 300px) {
  #mainTitle {
    margin-top: 6%;
    font-size: 30px;
  }

  #helloText {
    font-size: 11px;
  }

  #introText {
    margin-top: 10%;
  }

  .textHome {
    font-size: 11px;
    line-height: 20px;
  }

  #btn-contact {
    margin-top: 10%;
  }
}

@media (max-height: 470px) and (max-width: 650px) {
  #sectionHome {
    height: 590px;
  }

  #mainTitle {
    font-size: 30px;
  }

  #helloText {
    font-size: 11px;
  }

  .textHome {
    font-size: 11px;
  }
}